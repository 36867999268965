<template>
  <div>
    <h1 class="title">Batch Destination</h1>
    <div class="content">
      <p>
        Batch destinations are configurations of where fulfilment files are
        sent. Each destination can have different delivery methods
        (email/ftp/sftp), formats (csv/xml), protection (passworded zip/gpg) and
        a schedule of how often it is sent.
      </p>
      <p>
        They have a number of products (four letter codes) assigned to them that
        will be packaged up into the fulfilment file to send.
      </p>
      <p>
        We could have one destination for a subhouse for their format, or we
        could split one subhouse into multiple destinations for each publisher
        they might receive or into different formats as we please
      </p>
    </div>

    <br />

    <div class="columns" v-if="Config">
      <div class="column is-one-quarter">
        <div class="field">
          <label class="label">Name (Contains)</label>
          <div class="control">
            <div style="white-space:nowrap">
              <input
                class="input"
                type="text"
                placeholder="name"
                v-model="search.nameContains"
                v-on:change="updateFilter"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">File Format</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="search.fulfilmentClassName" v-on:change="updateFilter">
                <option :value="null">All</option>
                <option :value="c" v-for="c in Config.DataTransformClasses" :key="c">{{ c }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">Method Type</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="search.methodType" v-on:change="updateFilter">
                <option :value="null">All</option>
                <option :value="c" v-for="c in Config.MethodType" :key="c">
                  {{
                  c
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Format Type</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="search.formatType" v-on:change="updateFilter">
                <option :value="null">All</option>
                <option :value="c" v-for="c in Config.FormatType" :key="c">
                  {{
                  c
                  }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">Protection</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="search.fileProtection" v-on:change="updateFilter">
                <option :value="null">All</option>
                <option :value="c" v-for="c in Config.ProtectionType" :key="c">{{ c }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">Enabled</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="search.enabled" v-on:change="updateFilter">
                <option :value="null">All</option>
                <option :value="true">Yes</option>
                <option :value="false">No</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <div class="field">
          <label class="label">Page size</label>
          <div class="control">
            <div class="select is-fullwidth">
              <select v-model="search.size" v-on:change="updateFilter">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="columns" v-if="Destinations">
      <div class="column is-four-fifths" style="display:flex">
        <b-pagination
          size="is-small"
          :simple="false"
          :total="Destinations.TotalRows"
          :current.sync="search.currentPaginationPage"
          :per-page="Destinations.Limit"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          v-on:change="paginatePage"
        ></b-pagination>
      </div>
      <div class="column has-text-right">
        <button class="button is-primary is-medium" v-on:click="AddDestination">Add Destination</button>
      </div>
      <br />
    </div>

    <div v-if="isLoading">
      <a class="button is-large is-loading">Loading</a>
      <br />
      <br />
    </div>
    <table
      class="table is-size-6 is-fullwidth"
      v-else-if="Destinations !== null && Destinations.Results.length > 0"
    >
      <thead>
        <tr>
          <th>#</th>
          <th>Name</th>
          <th>
            CRON Schedule
            <br />Data format
          </th>
          <th>Config</th>
          <th>Linked Products</th>
          <th>Send Empty</th>
          <th>Enabled</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="d in Destinations.Results" :key="d.Id">
          <td>{{ d.Id }}</td>
          <td>
            <b>{{ d.Name }}</b>
          </td>
          <td :title="d.ScheduleCRON">
            <b>{{ CronAsWords(d.ScheduleCRON) }}</b>
            <span>
              <br />
              <span>formatted with</span>
              <br />
            </span>
            <b>'{{ d.FulfilmentClassName }}'</b>
          </td>
          <td>
            <p class="field">
              <span class="tag" :title="JSON.stringify(d.SettingsObject.SendSettings)">
                Method:&nbsp;
                <b>{{ MethodTypeString(d.MethodType) }}</b>
              </span>
            </p>
            <p class="field">
              <span class="tag" :title="JSON.stringify(d.SettingsObject.FormatSettings)">
                Format:&nbsp;
                <b>{{ FormatTypeString(d.FormatType) }}</b>
              </span>
            </p>
            <p class="field">
              <span class="tag" :title="JSON.stringify(d.SettingsObject.ProtectionSettings)">
                Protection:&nbsp;
                <b>{{ FileProtectionString(d.Protection) }}</b>
              </span>
            </p>
          </td>
          <td>
            <div>
              <button
                v-if="EnabledProducts(d).length > 0"
                class="button is-link is-small"
                v-on:click="DestinationProductsDialog = d"
              >{{ EnabledProducts(d).length }} Active</button>
            </div>
            <div>
              <button
                v-if="DisabledProducts(d).length > 0"
                class="button is-warning is-small"
                v-on:click="DestinationProductsDialog = d"
              >{{ DisabledProducts(d).length }} Disabled</button>
            </div>
          </td>
          <td>{{ d.SendEmpty ? 'Yes' : 'No' }}</td>
          <td>
            <button
              v-if="d.Enabled"
              class="button is-success is-small"
              v-on:click="ConfirmToggleEnabled(d)"
            >Yes</button>
            <button v-else class="button is-danger is-small" v-on:click="ConfirmToggleEnabled(d)">No</button>
          </td>
          <td>
            <b-dropdown aria-role="list" class="is-right">
              <button class="button is-primary is-small" slot="trigger">
                <span>Options</span>
                &nbsp;
                <i class="fas fa-caret-down"></i>
              </button>

              <b-dropdown-item
                aria-role="listitem"
                v-on:click="$router.push('/batch-jobs?destinationId=' + d.Id)"
              >Jobs History</b-dropdown-item>

              <b-dropdown-item
                class="is-danger"
                aria-role="listitem"
                v-on:click="
                  $router.push('/product-config?destinationId=' + d.Id)
                "
              >Linked Products</b-dropdown-item>

              <b-dropdown-item
                class="is-danger"
                aria-role="listitem"
                v-on:click="EditDestination = d"
              >Edit Destination</b-dropdown-item>

              <b-dropdown-item
                class="is-danger"
                aria-role="listitem"
                v-on:click="
                  $router.push('/fulfilment?batchDestinationId=' + d.Id)
                "
              >View Fulfilments</b-dropdown-item>

              <b-dropdown-item
                class="is-danger"
                aria-role="listitem"
                v-on:click="ConfirmCreateBatchJob(d)"
              >Create job and send</b-dropdown-item>
            </b-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      No Results found
      <br />
      <br />
    </div>

    <div class="columns" v-if="Destinations">
      <div class="column is-four-fifths" style="display:flex">
        <b-pagination
          size="is-small"
          :simple="false"
          :total="Destinations.TotalRows"
          :current.sync="search.currentPaginationPage"
          :per-page="Destinations.Limit"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page"
          v-on:change="paginatePage"
        ></b-pagination>
      </div>
      <div class="column has-text-right">
        <button class="button is-primary is-medium" v-on:click="AddDestination">Add Destination</button>
      </div>
    </div>

    <batch-destination-products
      v-if="DestinationProductsDialog !== null"
      v-on:close="DestinationProductsDialog = null"
      :batch-destination.sync="DestinationProductsDialog"
    ></batch-destination-products>

    <batch-destination-insert-or-update
      v-if="EditDestination"
      :edit-destination="EditDestination"
      v-on:close="EditDestination = null"
      v-on:add="AddDestinationSuccess"
    ></batch-destination-insert-or-update>
  </div>
</template>
<script>
import cronstrue from "cronstrue";

export default {
  components: {
    batchDestinationProducts: () =>
      import("../components/batchDestinationProducts.vue"),

    batchDestinationInsertOrUpdate: () =>
      import("../components/batchDestinationInsertOrUpdate.vue")
  },
  data: function() {
    return {
      APIUrl: process.env.VUE_APP_APIURL,
      Destinations: null,
      Config: null,
      EditDestination: null,
      DestinationProductsDialog: null,
      isLoading: false,
      search: {
        currentPaginationPage: 1,
        skip: null,
        size: 10,
        nameContains: null,
        fulfilmentClassName: null,
        methodType: null,
        formatType: null,
        fileProtection: null,
        enabled: null,
        sendEmpty: null
      }
    };
  },
  created() {
    this.loadConfig();

    this.search.size = this.$route.query.size || 10;

    this.search.skip = this.$route.query.skip
      ? parseInt(this.$route.query.skip) * this.search.size
      : 0;

    this.setDataFromQuery();

    // this.loadDestinationOptions();
    this.updateUrlAndLoadPage(true);

    // this.loadPage();
  },
  methods: {
    setDataFromQuery() {
      var self = this;
      this.listOfSearchParameters.forEach(function(s) {
        if (self.$route.query[s]) self.search[s] = self.$route.query[s];
      });
    },
    loadConfig() {
      var url = this.APIUrl + "/destination/config";
      this.axios.get(url).then(response => {
        this.Config = response.data;
      });
    },
    MethodTypeString(id) {
      return this.Config.MethodType[id];
    },
    FormatTypeString(id) {
      return this.Config.FormatType[id];
    },
    FileProtectionString(id) {
      return this.Config.ProtectionType[id];
    },
    CronAsWords(str) {
      return cronstrue.toString(str);
    },
    AddDestination() {
      this.EditDestination = {
        Id: null,
        ScheduleCRON: "30 1 * * MON",
        Name: "",
        Enabled: true,
        SendEmpty: true,
        FulfilmentClassName: "",
        Filename: "",
        MethodType: 3,
        FormatType: 0,
        Protection: 0,
        ContactGroupId: null,
        SettingsObject: {
          SendSettings: {},
          FormatSettings: {},
          ProtectionSettings: {}
        }
      };
    },
    AddDestinationSuccess(destination) {
      this.EditDestination = null;
      this.loadData();
      //add new destination added
    },
    ConfirmToggleEnabled(d) {
      var isEnabled = d.Enabled;

      var msg = d.Enabled
        ? "Are you sure you want to <b>DISABLE</b> this destination, no fulfilments will be sent"
        : "Are you sure you want to <b>ENABLE</b> this destination, it will start sending fulfilments";

      msg +=
        "<br><b>Assoicated with " + d.ProductsLinked.length + " products</b>";

      var title = (d.Enabled ? "Disable " : "Enable ") + d.Name + "?";

      this.$dialog.confirm({
        title: title,
        message: msg,
        _type: "is-danger",
        get type() {
          return this._type;
        },
        set type(value) {
          this._type = value;
        },
        onConfirm: () => this.ToggleEnabled(d)
      });
    },
    ToggleEnabled(d) {
      var newState = !d.Enabled;
      var url = this.APIUrl + "/destination/" + d.Id + "/enabled/" + newState;
      this.axios.put(url).then(response => {
        d.Enabled = newState;
        var state = newState ? "ENABLED" : "DISABLED";
        this.$toast.open(
          "Successfully updated destination <b>'" +
            d.Name +
            "'</b> state to: <b>'" +
            state +
            "'</b>"
        );
      });
    },
    ConfirmCreateBatchJob(d) {
      var msg =
        "Are you sure you want to <b>Create a new job</b> this destination, this will pick up any unassigned fulfilments and send them now.";

      var title = "Create job for " + d.Name + "?";

      this.$dialog.confirm({
        title: title,
        message: msg,
        _type: "is-information",
        get type() {
          return this._type;
        },
        set type(value) {
          this._type = value;
        },
        onConfirm: () => this.CreateBatchJob(d)
      });
    },
    CreateBatchJob(d) {
      var url = this.APIUrl + "/destination/" + d.Id + "/job/create";
      this.axios.post(url).then(response => {
        this.$toast.open(
          "Successfully created job for destination <b>'" +
            d.Name +
            "'</b>, it should be sent within a couple minutes."
        );
      });
    },
    EnabledProducts(d) {
      return d.ProductsLinked.filter(function(p) {
        return p.Enabled;
      });
    },
    DisabledProducts(d) {
      return d.ProductsLinked.filter(function(p) {
        return !p.Enabled;
      });
    },
    updateFilter() {
      this.search.skip = 0;
      this.search.currentPaginationPage = 1;
      this.updateUrlAndLoadPage();
    },
    updateUrlAndLoadPage(replace) {
      var url = "batch-destination" + this.queryStringSearch;

      if (replace) this.$router.replace(url);
      else this.$router.push(url);

      this.loadData();
    },
    loadData() {
      this.isLoading = true;
      this.axios.get(this.apiEndpointUrl).then(response => {
        this.Destinations = response.data;
        this.isLoading = false;
      });
    },
    paginatePage(page) {
      this.search.currentPaginationPage = page;
      if (this.search.currentPaginationPage <= 0)
        this.search.currentPaginationPage = 1;
      this.search.skip =
        (this.search.currentPaginationPage - 1) * parseInt(this.search.size);
      this.updateUrlAndLoadPage();
    }
  },
  computed: {
    apiEndpointUrl: function() {
      var url = this.APIUrl + "/destination/all" + this.queryStringSearch;
      return url;
    },
    queryStringSearch: function() {
      var url = "?take=" + this.search.size + "&skip=" + this.search.skip;

      var self = this;
      this.listOfSearchParameters.forEach(function(s) {
        if (self.search[s] !== null) url += "&" + s + "=" + self.search[s];
      });

      return url;
    },
    listOfSearchParameters() {
      var arr = [
        "nameContains",
        "fulfilmentClassName",
        "methodType",
        "formatType",
        "fileProtection",
        "enabled",
        "sendEmpty"
      ];
      return arr;
    }
  }
};
</script>
